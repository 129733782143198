<div class="mat-elevation-z8">
  <table mat-table [dataSource]="data.selectedGamePlayByPlayDataSource" style="width: 100%">
    <ng-container matColumnDef="move">
      <th mat-header-cell *matHeaderCellDef style="color: white; width: 200px">
        move
      </th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        {{ element.move }}
      </td>
    </ng-container>

    <ng-container matColumnDef="board">
      <th
        mat-header-cell
        *matHeaderCellDef
        style="color: white; width: 90%; padding-left: 10px"
      >
        board after move
      </th>
      <td mat-cell *matCellDef="let element" style="padding-left: 10px">
        <table>
          <tr>
            <td
              *ngFor="let board of element.board; let i = index"
              style="vertical-align: top"
            >
              <div
                *ngIf="element.board.length > 1"
                style="width: 100; text-align: left"
              >
                <span colspan="10" *ngIf="i === 0">Before </span>
                <span colspan="10" *ngIf="i === 1">After </span>
                {{
                  engine.boardRows(element.board[0]).length -
                    engine.boardRows(element.board[1]).length
                }}
                row<span
                  *ngIf="
                    engine.boardRows(element.board[0]).length -
                      engine.boardRows(element.board[1]).length !==
                    1
                  "
                  >s</span
                >
                removed:
              </div>

              <div
                *ngIf="!board || board.length == 0"
                style="
                  width: 250px;
                  height: 47px;
                  background-color: rgba(0, 0, 0, 0.344);
                  color: white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                class="bevel"
              >
                Board is Empty
              </div>

              <table
                style="padding: 0; border-collapse: collapse"
                cellspacing="0"
                cellpadding="0"
              >
                <tr *ngFor="let row of engine.rowNumbers(board).reverse()">
                  <td
                    *ngFor="let cell of boardCells"
                    style="
                      border: 1px solid rgba(111, 103, 103, 0.35);
                      width: 22px;
                      min-width: 22px;
                      height: 22px;
                      min-height: 22px;
                      text-align: center;
                    "
                    class="bevel"
                    [ngStyle]="{ 'background-color': board[row * 10 + cell] }"
                  >
                    {{ row * 10 + cell }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </ng-container>

    <ng-container matColumnDef="rows">
      <th mat-header-cell *matHeaderCellDef style="color: white; width: 60px">
        rows
      </th>
      <td mat-cell *matCellDef="let element" style="text-align: right">
        <div style="display: flex; justify-content: space-between; width: 100%">
          <span *ngIf="element.board.length === 1"></span>
          <span *ngIf="element.board.length > 1">
            {{ engine.boardRows(element.board[0]).length }} / </span
          ><span
            >{{
              engine.boardRows(element.board[element.board.length - 1]).length
            }}
          </span>
        </div>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
      style="
        font-weight: bolder;
        color: white;
        background-color: #2b5876;

      "
    ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

</div>
