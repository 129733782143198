import { AfterViewInit, Component } from '@angular/core';

import { GameEngineService } from 'src/app/services/game-engine.service';
import { GameResultDataService } from 'src/app/services/game-result-data.service';

@Component({
  selector: 'app-play-by-play',
  templateUrl: './play-by-play.component.html',
  styleUrls: ['./play-by-play.component.scss']
})
export class PlayByPlayComponent {

  boardCells = [...Array(10).keys()];

  displayedColumns = ['move', 'board', 'rows'];
  length = 100;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(public data: GameResultDataService, public engine: GameEngineService) {
  }


}
