<mat-horizontal-stepper [linear]="true">
  <mat-step optional="false">
    <ng-template matStepLabel>Inputs</ng-template>
    <app-bulk-input></app-bulk-input>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Results</ng-template>
    <app-results></app-results>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>View</ng-template>
    <app-view-game></app-view-game>
  </mat-step>
</mat-horizontal-stepper>
