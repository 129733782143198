
import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GameEngineService } from 'src/app/services/game-engine.service';

import { gsap, CSSPlugin, TimelineMax } from 'gsap';

gsap.registerPlugin(CSSPlugin, TimelineMax);

@Component({
  selector: 'app-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.scss']
})
export class AnimationComponent implements OnInit {

  @ViewChild('shape') shape: ElementRef;
  @ViewChild('table1') table1: ElementRef;
  @ViewChild('table2') table2: ElementRef;

  boardCells = [...Array(10).keys()];
  boardCellsReversed = [...Array(10).keys()].reverse();

  spriteCells = [...Array(4).keys()];
  spriteCellsReversed = [...Array(4).keys()].reverse();

  selectedShape = [];
  selectedColor = this.engine.colors[0];

  board = [];
  board2 = [];

  currentPlay = -1;
  currentBoardRows = 0;

  rowHeight = 26;
  showBars = false;

  selectedGameAnimated = [];

  constructor(
    public engine: GameEngineService,
    private chg: ChangeDetectorRef,
    public renderer: Renderer2) { }

  ngOnInit() {
    const ls = localStorage.getItem('selectedGameAnimated');
    this.selectedGameAnimated = JSON.parse(ls);
    console.log('ngOnInit');

    if (this.selectedGameAnimated) {
      setTimeout(() => {
        this.playNextFrame();
      }, 500);
    }
  }



  playNextFrame() {

    if (this.currentPlay >= this.selectedGameAnimated.length + 2) {
      this.currentPlay = -1;
      this.currentBoardRows = 0;
      this.board = [];
      this.chg.detectChanges();
    }

    this.currentPlay++;

    const play = this.selectedGameAnimated[this.currentPlay];
    if (play) {

      let colorIndex = this.currentPlay;
      while (colorIndex > this.engine.colors.length - 1) { colorIndex -= this.engine.colors.length; }

      const left = play.move[1] * 26;
      const topRow = 10 - play.row;
      const top = (topRow * this.rowHeight) - 3.51;
      const speed = .2 * topRow;

      const shape = this.shape.nativeElement;
      const shapeAnimation = new TimelineMax({ paused: true })
        .to(shape, 0, { opacity: 0, top: -20 + 'px', x: left + 'px' })
        .to(shape, 1.5, { opacity: 1 }, .5)
        .to(shape, speed, { top: top + 'px', x: left + 'px', ease: 'Expo.easeIn' }, '-=1.5')
        .to(shape, 2, {}, 1)
        .to(shape, 0, { opacity: 0 })
        ;

      this.selectedColor = this.engine.colors[colorIndex];
      this.selectedShape = this.engine.Shapes[play.move[0]];

      this.chg.detectChanges();

      shapeAnimation.play();
      shapeAnimation.eventCallback('onComplete', () => {

        this.selectedColor = undefined;
        this.selectedShape = [];
        this.chg.detectChanges();

        if (play?.board?.length > 1) {

          const t1 = this.table1.nativeElement;
          const t2 = this.table2.nativeElement;

          this.renderer.setStyle(t1, 'opacity', 0);
          this.renderer.setStyle(t2, 'opacity', 1);

          this.board = play.board[1];
          this.board2 = play.board[0];

          this.currentBoardRows = play.finalRowsAsArray[0];

          this.chg.detectChanges();

          const boardAnimation = new TimelineMax({ paused: true })
            .to(t1, .5, { opacity: 1 })
            .to(t2, .5, { opacity: 0 }, .5)
            .play();

          boardAnimation.eventCallback('onComplete', () => {
            this.board2 = [];
            this.currentBoardRows = play?.finalRowsAsArray[1];
            this.chg.detectChanges();

            this.renderer.setStyle(t1, 'opacity', 1);
            this.renderer.setStyle(t2, 'opacity', 0);
          });
        } else {
          this.board = play?.board[0];
          this.currentBoardRows = play?.finalRowsAsArray[0];
        }

        this.chg.detectChanges();
        this.playNextFrame();
      });

    } else {
      setTimeout(() => { this.playNextFrame(); }, 2000);
    }

  }

}
