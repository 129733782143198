<div
  style="width: 100%; height: 100vh; display: flex; flex-direction: column"
  [ngSwitch]="selectedView"
>
  <span style="height: 64px">
    <mat-toolbar color="primary">
      <div style="display: flex; justify-content: space-between; width: 100%">
        <span>Jeff Hughes' Simplified Tetris Engine v0.0.1</span>
        <span style="display: flex">
          <button
            mat-raised-button
            *ngIf="selectedView != 'documentation'"
            (click)="selectedView = 'documentation'"
            style="margin-right: 10px"
          >
            Documentation
          </button>

          <button
            mat-raised-button
            *ngIf="selectedView != 'console'"
            (click)="selectedView = 'console'"
            [ngStyle]="{
              'margin-right': selectedView != 'interactive' ? '10px' : ''
            }"
          >
            Console App
          </button>

          <button
            mat-raised-button
            *ngIf="selectedView != 'interactive'"
            (click)="selectedView = 'interactive'"
          >
            Interactive App
          </button>
        </span>
      </div>
    </mat-toolbar>
  </span>

  <span *ngSwitchCase="'interactive'">
    <app-wizard></app-wizard>

    <div
      style="
        position: fixed;
        bottom: 5px;
        left: 20px;
        background-color: white;
        width: 400px;
        min-width: 400px;
        color: lightgray;
        border: 1px solid gray;
      "
    >
      &nbsp; test app intended for Chrome 81+, resolutions > 400px wide
    </div>

    <br /><br /><br />
  </span>

  <span *ngSwitchCase="'console'" style="width: 100%; flex: 1">
    <iframe
      height="100%"
      width="100%"
      src="https://repl.it/@JeffHughes3/SimplifiedTetrisEngine?lite=true"
      scrolling="no"
      frameborder="no"
      allowtransparency="true"
      allowfullscreen="true"
      sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-modals"
    ></iframe>
  </span>

  <span *ngSwitchCase="'documentation'" style="width: 100%; flex: 1">
    <iframe
      height="100%"
      width="100%"
      src="https://tetris-docs.jeffhughes.com/injectables/GameEngineService.html#source"
      scrolling="no"
      frameborder="no"
      allowtransparency="true"
      allowfullscreen="true"
      sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-modals"
    ></iframe>
  </span>
</div>
