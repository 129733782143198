import { AfterViewInit } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

import { GameResultDataService } from 'src/app/services/game-result-data.service';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['finalRows', 'moves', 'animation'];

  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 50];

  includeMovesInTextOutput = true;

  constructor(public data: GameResultDataService) { }

  ngAfterViewInit() {
    this.data.paginator = this.paginator;
  }

  updateGameResults(checked) {
    this.includeMovesInTextOutput = checked;


  }



  gameResultsToTxt() {
    let output = '';
    this.data.gameResults.forEach(result => {
      output += result.finalRows;

      if (this.includeMovesInTextOutput) {
        output += ' ' + result.moves;
      }

      output += '\n';
    });
    return output;
  }

  gameResultsToJSON() {
    return JSON.stringify(this.data.gameResults);
  }

  downloadTXTResult() {
    const txt = this.gameResultsToTxt();
    this.createAndDownloadFile('output.txt', txt);
  }

  downloadJSONResult() {
    const json = this.gameResultsToJSON();
    this.createAndDownloadFile('output.json', json);
  }

  createAndDownloadFile(
    fileName: string,
    text: string
  ) {
    const setting = {
      element: {
        dynamicDownload: null as HTMLElement
      }
    };

    if (!setting.element.dynamicDownload) {
      setting.element.dynamicDownload = document.createElement('a');
    }
    const element = setting.element.dynamicDownload;
    const fileType = fileName.includes('.json') ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute('download', fileName);

    const event = new MouseEvent('click');
    element.dispatchEvent(event);
  }

}
