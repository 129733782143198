import { AfterViewInit, Component, ElementRef,   ViewChild } from '@angular/core';
import { GameResultDataService } from 'src/app/services/game-result-data.service';

@Component({
  selector: 'app-view-game',
  templateUrl: './view-game.component.html',
  styleUrls: ['./view-game.component.scss']
})
export class ViewGameComponent implements AfterViewInit {

  @ViewChild('animation') animation: ElementRef;

  constructor(public data: GameResultDataService) { }

  ngAfterViewInit(): void {
    this.data.animationFrame = this.animation;
  }

}
