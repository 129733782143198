import { ChangeDetectorRef, Component } from '@angular/core';

import { GameEngineService } from 'src/app/services/game-engine.service';
import { GameResultDataService } from 'src/app/services/game-result-data.service';

@Component({
  selector: 'app-bulk-input',
  templateUrl: './bulk-input.component.html',
  styleUrls: ['./bulk-input.component.scss']
})
export class BulkInputComponent {

  constructor(public data: GameResultDataService,
              public engine: GameEngineService,
              public chg: ChangeDetectorRef
  ) { }

  updateInputs(input) {

    this.data.gameResults = [];

    input.value
      .replace(/\r\n/g, '\n') // normalizing input
      .split('\n')
      .forEach(moves => { // foreach valid line in the input text, run the eval e.g. Q0,Q2,Q4
        if (moves.trim().length > 1) {
          this.data.gameResults.push({ finalRows: this.engine.evalMovesForFinalRowCount(moves.trim().split(',')), moves });
        }
      });

    this.data.updateTableDataSource(this.data.gameResults);
  }

}
