<div class="sub-header bottom-vert">
  <span class="sub-header-text">Bulk Game Input:</span>
</div>

<mat-form-field style="width: 100%">
  <mat-label> comma separated list of moves, one game per line</mat-label>
  <textarea
    matInput
    cdkTextareaAutosize
    autosize="true"
    cdkAutosizeMinRows="1"
    cdkAutosizeMaxRows="50"
    style="border: 1px solid rgba(211, 211, 211, 0.2); width: 100%"
    (change)="updateInputs(input)"
    #input
  >
Q0
Q0,Q1
Q0,Q2,Q4,Q6,Q8
Q0,Q2,Q4,Q6,Q8,Q1
Q0,Q2,Q4,Q6,Q8,Q1,Q1
I0,I4,Q8
I0,I4,Q8,I0,I4
L0,J2,L4,J6,Q8
L0,Z1,Z3,Z5,Z7
T0,T3
T0,T3,I6,I6
I0,I6,S4
T1,Z3,I4
L0,J3,L5,J8,T1
L0,J3,L5,J8,T1,T6
L0,J3,L5,J8,T1,T6,J2,L6,T0,T7
L0,J3,L5,J8,T1,T6,J2,L6,T0,T7,Q4
S0,S2,S4,S6
S0,S2,S4,S5,Q8,Q8,Q8,Q8,T1,Q1,I0,Q4
L0,J3,L5,J8,T1,T6,S2,Z5,T0,T7
Q0,I2,I6,I0,I6,I6,Q2,Q4
</textarea
  >
</mat-form-field>
<span style="display: flex; justify-content: space-between">
  <span>
    Valid letters are Q, Z, S, T, I, L, and J & valid numbers are between 0-9
  </span>
  <button
    mat-raised-button
    matStepperNext
    color="primary"
    (click)="updateInputs(input)"
  >
    Process &gt;
  </button>
</span>

<a
color="primary"
mat-raised-button
href="/assets/JeffHughesTetris3.zip"
>AutoTesting Solution</a
>
