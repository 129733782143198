import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { LayoutComponent } from './layout/layout.component';
import { WizardComponent } from './layout/wizard/wizard.component';
import { BulkInputComponent } from './layout/wizard/bulk-input/bulk-input.component';
import { ResultsComponent } from './layout/wizard/results/results.component';
import { ViewGameComponent } from './layout/wizard/view-game/view-game.component';
import { PlayByPlayComponent } from './layout/wizard/view-game/play-by-play/play-by-play.component';
import { AnimationComponent } from './layout/wizard/view-game/animation/animation.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    WizardComponent,
    BulkInputComponent,
    ResultsComponent,
    ViewGameComponent,
    PlayByPlayComponent,
    AnimationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // angular material modules
    MatToolbarModule,
    MatStepperModule,
    MatFormFieldModule,
    TextFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    MatCheckboxModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
