import { ElementRef, Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GameEngineService, GameResult } from './game-engine.service';

export interface GamePlay {
  move: string;
  board: [];
}

@Injectable({
  providedIn: 'root'
})
export class GameResultDataService {

  public gameResults = [];
  public selectedGameResult: GameResult;
  public selectedGamePlayByPlay = [];

  public tableDataSource = new MatTableDataSource<GameResult>(this.gameResults);
  public selectedGamePlayByPlayDataSource = new MatTableDataSource<GamePlay>(this.selectedGamePlayByPlay);

  public paginator: MatPaginator;
  public animationFrame: ElementRef;

  constructor(public engine: GameEngineService) { }

  updateTableDataSource(gameResults) {

    if (gameResults && gameResults.length > 0) {
      this.gameResults = gameResults;

      this.tableDataSource = new MatTableDataSource<GameResult>(this.gameResults);
      this.tableDataSource.paginator = this.paginator;

      this.updateSelectedGame(this.gameResults[0]);
    }
  }

  updateSelectedGame(game: GameResult) {
    this.selectedGameResult = game;
    this.playByPlayData(this.selectedGameResult);
  }

  playByPlayData(game: GameResult) {
    this.selectedGamePlayByPlay = [];
    const selectedGameAnimated = [];

    const gameToHere = [];

    game.moves.split(',').forEach(move => {
      gameToHere.push(move);
      this.selectedGamePlayByPlay.push({ move, board: this.engine.getColorBoard(gameToHere) });

      const board = this.engine.getColorBoard(gameToHere);
      selectedGameAnimated.push({
        move,
        board,
        row: this.engine.evalMovesForRow(gameToHere),
        finalDisplayRows: this.getFinalDisplayRows(board),
        finalRowsAsArray: this.getFinalRowsAsArray(board),
      });
      localStorage.setItem('selectedGameAnimated', JSON.stringify(selectedGameAnimated));
      this.animationFrame?.nativeElement?.contentWindow?.location?.reload();
    });

    this.paginator.pageIndex = 0;
    this.selectedGamePlayByPlayDataSource = new MatTableDataSource<GamePlay>(this.selectedGamePlayByPlay);
  }

  getFinalRowsAsArray(board) {
    return board.map(b => this.engine.rowCount(b));
  }

  getFinalDisplayRows(board) {
    let output = this.engine.rowCount(board[0]).toString();
    if (board.length > 1) {
      output = output + '/' + this.engine.rowCount(board[1]);
    }
    return output;
  }

}
