<mat-tab-group selectedIndex="0">
  <div style="min-height: 400px; height: 400px">
    <mat-tab label="Interactive">
      <div class="mat-elevation-z8">
        <table
          mat-table
          [dataSource]="data.tableDataSource"
          style="width: 100%"
        >
          <ng-container matColumnDef="finalRows">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="width: 60px; min-width: 60px"
            >
              Final Rows
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: center">
              {{ element.finalRows }}
            </td>
          </ng-container>

          <ng-container matColumnDef="moves">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="width: auto; padding-left: 10px"
            >
              Moves
            </th>
            <td mat-cell *matCellDef="let element" style="padding-left: 10px">
              {{ element.moves }}
            </td>
          </ng-container>

          <ng-container matColumnDef="animation">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="header-align-right"
              style="width: 80px; min-width: 80px"
            >
              Inspect Game
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              style="text-align: center"
              (click)="data.updateSelectedGame(element)"
            >
              <button mat-raised-button matStepperNext color="primary">
                View >
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <div
          *ngIf="data.gameResults.length === 0"
          style="width: 100%; height: 150px"
          class="center-horiz-vert"
        >
          <div>No records found</div>
        </div>

        <mat-paginator
          [length]="data.gameResults.length"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </mat-tab>
  </div>
  <mat-tab label="Text">
    <div style="min-height: 460px; height: 460px; overflow: hidden">
      <div class="sub-header bottom-vert">
        <span class="sub-header-text"
          >Results as Text &nbsp;
          <mat-checkbox
            class="example-margin"
            [checked]="includeMovesInTextOutput"
            (change)="updateGameResults( $event.checked)"
          >
            Include moves with Final Rows
          </mat-checkbox>
        </span>
      </div>
      <textarea
        matInput
        cdkTextareaAutosize
        autosize="true"
        cdkAutosizeMinRows="50"
        cdkAutosizeMaxRows="50"
        style="border: 1px solid rgba(211, 211, 211, 0.2); width: 100%"
        >{{ gameResultsToTxt() }}</textarea
      >
    </div>
  </mat-tab>

  <mat-tab label="JSON">
    <div
      style="
        min-height: 460px;
        height: 460px;
        max-height: 460px;
        overflow: hidden;
      "
    >
      <div class="sub-header bottom-vert">
        <span class="sub-header-text">Results as JSON</span>
      </div>
      <textarea
        matInput
        cdkTextareaAutosize
        autosize="true"
        cdkAutosizeMinRows="50"
        cdkAutosizeMaxRows="50"
        style="border: 1px solid rgba(211, 211, 211, 0.2); width: 100%"
        >{{ gameResultsToJSON() }}</textarea
      >
    </div>
  </mat-tab>
</mat-tab-group>

<br />

<span style="display: flex; justify-content: space-between">
  <button mat-raised-button matStepperPrevious color="primary">
    &lt; Back
  </button>
  <span>
    <button mat-raised-button (click)="downloadTXTResult()">
      Export as TXT
    </button>
    <button mat-raised-button (click)="downloadJSONResult()">
      Export as JSON
    </button>
  </span>
</span>
