<br />

<ng-container *ngIf="this.selectedGameAnimated">
  <div style="display: flex; width: 100%; align-content: center">
    <div style="margin: 0 auto">
      <table style="width: 400px; padding: 10px" class="mat-elevation-z8">
        <tr>
          <td style="height: 375px; width: 260px">
            <div style="position: relative; height: 375px; width: 100%">
              <ng-container *ngIf="showBars">
                <div style="position: absolute; top: 360px; left: 125px">
                  {{ currentBoardRows }}
                </div>

                <!--diagonal bars only  -->
                <table
                  *ngIf="showBars"
                  class="board-table"
                  cellspacing="0"
                  cellpadding="0"
                >
                  <tr
                    *ngFor="let row of boardCellsReversed; let i = index"
                    [ngClass]="{
                      'diag-bars': 10 - currentBoardRows <= i
                    }"
                  >
                    <td
                      *ngFor="let cell of boardCells"
                      style="border: 1px solid rgba(111, 103, 103, 0)"
                      class="square-td"
                    ></td>
                  </tr>
                </table>
              </ng-container>

              <!-- moving shape-->
              <table
                #shape
                style="
                  padding: 0;
                  border-collapse: collapse;
                  position: absolute;
                "
                cellspacing="0"
                cellpadding="0"
              >
                <tr *ngFor="let row of spriteCellsReversed">
                  <td
                    *ngFor="let cell of spriteCells"
                    class="square-td"
                    [ngStyle]="{
                      'background-color': selectedShape.includes(
                        row * 10 + cell
                      )
                        ? selectedColor
                        : '',
                      border: selectedShape.includes(row * 10 + cell)
                        ? '1px solid rgba(111, 103, 103, 0.65)'
                        : '1px solid rgba(111, 103, 103, 0.01)'
                    }"
                  ></td>
                </tr>
              </table>

              <!--board 1 -->
              <table
                #table1
                class="board-table"
                cellspacing="0"
                cellpadding="0"
              >
                <tr *ngFor="let row of boardCellsReversed">
                  <td
                    class="square-td"
                    *ngFor="let cell of boardCells"
                    style="border: 1px solid rgba(111, 103, 103, 0)"
                    [ngStyle]="{ 'background-color': board[row * 10 + cell] }"
                  ></td>
                </tr>
              </table>

              <table
                #table2
                class="board-table"
                cellspacing="0"
                cellpadding="0"
                style="opacity: 0"
              >
                <tr *ngFor="let row of boardCellsReversed">
                  <td
                    class="square-td"
                    *ngFor="let cell of boardCells"
                    style="border: 1px solid rgba(111, 103, 103, 0)"
                    [ngStyle]="{ 'background-color': board2[row * 10 + cell] }"
                  ></td>
                </tr>
              </table>

              <!--numbers only  -->
              <table
                class="board-table"
                cellspacing="0"
                cellpadding="0"
                (click)="showBars = !showBars"
              >
                <tr *ngFor="let row of boardCellsReversed; let i = index">
                  <td
                    *ngFor="let cell of boardCells"
                    style="border: 1px solid rgba(111, 103, 103, 0.65)"
                    class="bevel square-td"
                  >
                    {{ row * 10 + cell }}
                  </td>
                </tr>
              </table>
            </div>
          </td>
          <td style="width: 100px; vertical-align: top">
            <table style="width: 100%">
              <tr
                style="
                  color: white;
                  background-color: #2b5876;
                  background-image: linear-gradient(to right, #2b5876, #3f51b5);
                "
              >
                <td>#</td>
                <td>Move</td>
                <td>Rows</td>
              </tr>
              <tr
                *ngFor="let move of selectedGameAnimated; let i = index"
                [ngStyle]="{
                  'background-color': i == currentPlay ? '#3f51b54a' : ''
                }"
                class="fader"
              >
                <td>{{ i + 1 }}.</td>
                <td style="text-align: center">{{ move.move }}</td>
                <td style="text-align: center">{{ move.finalDisplayRows }}</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </div>
</ng-container>

<div
  *ngIf="!this.selectedGameAnimated"
  style="padding: 25px; font-size: larger"
>
  Loading...

  <br /><br />

  A game has to be selected from the <a href="/">main app</a> for the animation
  to work
</div>
