<div style="display: flex; justify-content: space-between">
  <span>
    <span
      >{{ data.selectedGameResult?.moves?.split(",").length }} move<span
        *ngIf="data.selectedGameResult?.moves?.split(',').length !== 1"
        >s</span
      ></span
    >:
    {{ data.selectedGameResult?.moves }}
  </span>

  <span style="padding-right: 20px"
    >{{ data.selectedGameResult?.finalRows }} final row<span
      *ngIf="data.selectedGameResult?.finalRows !== 1"
      >s</span
    ></span
  >
</div>

<mat-tab-group selectedIndex="0">
  <div style="min-height: 400px; height: 400px">
    <mat-tab label="Move-by-Move">
      <app-play-by-play></app-play-by-play>
    </mat-tab>
  </div>
  <mat-tab label="Animation">
    <div style="min-height: 490px; height: 490px; overflow: hidden">
      <div class="sub-header bottom-vert">
        <span class="sub-header-text">game in motion</span>
      </div>
      <!-- usually not the biggest iframe fan,
        but it automagically auto loads on coming into view
        without any additional effort and
        it defeats the mat styles that cause errors in the animation-->
      <iframe
        #animation
        src="/animation"
        style="overflow: hidden"
        width="100%"
        height="450"
        frameBorder="0"
      >
      </iframe>
    </div>
  </mat-tab>
</mat-tab-group>

<button mat-raised-button matStepperPrevious color="primary">< Back</button>
&nbsp;

